<template>
  <crud-form
    :schema="schema"
    :model-id="id"
    :path="'payment-method'"
    :form-title="$t('updatePaymentMethod')"
    :redirect-route="'PaymentMethodList'"
  ></crud-form>
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './form/delivery'

  export default {
    name: 'UpdatePaymentMethod',
    props: ['id'],
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
      }
    },
  }
</script>
